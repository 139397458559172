import Vue from "vue";
import VueRouter from "vue-router";
// 1. 定义 (路由) 组件。
// 可以从其他文件 import 进来

Vue.use(VueRouter);

// 2. 定义路由
// 每个路由应该映射一个组件。
const routes = [
    {
        path: "/",
        name: "Login",
        // 路由懒加载
        component: (resolve) => require(["@/views/login/index"], resolve),
        meta: {title: "咩咩宠物"}
    }, {
        path: "/article",
        name: "Article",
        // 路由懒加载
        component: (resolve) => require(["@/views/article/index"], resolve),
        meta: {title: "咩咩宠物"}
    },
];
// 3. 创建 router 实例，然后传 `routes` 配置
const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
