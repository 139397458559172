<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {}
}
</script>

<style>
* {
  margin: 0px;
  padding: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

img {
  width: 100%;
  vertical-align: bottom;
  display: block;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}

i {
  font-style: normal;
}

input,
textarea,
select {
  vertical-align: middle;
  color: #666;
  outline: none;
  font-family: 'Helvetica';
  border: none;
  resize: none;
  background-color: transparent;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

button {
  outline: none;
  cursor: pointer;
}

select {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix:after {
  content: "";
  height: 0;
  visibility: hidden;
  display: block;
  clear: both;
}

.clearfix {
  zoom: 1;
}

.disnone {
  display: none;
}

.flex {
  display: flex;
}

.flexAtcent {
  align-items: center;
}

.flexJcent {
  justify-content: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0 60px white inset;
  -webkit-text-fill-color: #878787;
}
</style>
