import Vue from 'vue'
import App from './App.vue'

// 路由组件
import router from "./router";

// px转rem
import 'amfe-flexible'

// Mint组件
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'

Vue.use(Mint);

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
